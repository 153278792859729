import React from 'react'

import { IMAGE_SIZE, makeImageURL } from 'mednet-util/src/router'
import { formatName } from 'mednet-util/src/string'
import { Subtitle2, Subtitle3 } from 'pharmacy/src/typography'

import css from './mentionHit.scss'

class MentionHit extends React.Component {
  render() {
    const { hit } = this.props
    const { userId, imageVersion, institution, _highlightResult } = hit
    const { firstName, middleName, lastName } = _highlightResult || {}

    const profilePicture = makeImageURL(userId, imageVersion, IMAGE_SIZE.MEDIUM)

    const hitContent = formatName(
      firstName.value,
      middleName.value,
      lastName.value,
      '',
      { useMiddleInitial: false }
    )

    return (
      <div className={css.container}>
        <div className={css.imageContainer}>
          <img className={css.image} src={profilePicture} />
        </div>
        <div className={css.infoContainer}>
          <Subtitle2 important>
            <div
              className={css.hitContent}
              dangerouslySetInnerHTML={{ __html: hitContent }}
            />
          </Subtitle2>
          <Subtitle3 important>{institution}</Subtitle3>
        </div>
      </div>
    )
  }
}

export default MentionHit
